import React from "react";
import EidAdha from "./EidAdha.png";

const SvgComponent = (props) => (
  
  <div style={{ overflowX: "scroll", direction: "rtl" }}>
    <svg
    
      id="theCard"
      style={{ width: "44.625em", height: "48.510em" }}
      {...props}
    >
      <image
        height={"100%"}
        width={"100%"}
        style={{ direction: "rtl" }}
        xlinkHref={EidAdha}
        fill="none"
        fillRule="evenodd"
      />
      <text
        x="357"
        y="700"
        textAnchor="middle"
        direction="rtl"
        fontFamily="urw"
        fontSize="1.5em"
        fill="#606060"
      >
        {props.name}
      </text>
    </svg>
  </div>
);
export default SvgComponent;
